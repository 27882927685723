import React from "react";
import assests from "../assets/assets";

const Contact = () => {
  return (
    <div className="container fluid">
      <div className="d-flex align-items-center justify-content-center py-lg-5 py-4">
        <img
          src={assests.images.contact}
          className="img-fluid"
          alt="homeImage"
          style={{ width: "80px", height: "80px" }}
        />
        <h1
          className="mb-0 ms-2 text-decoration-underline"
          style={{ color: "#178FEB" }}
        >
          Contact Us
        </h1>
      </div>
      <div
        className="rounded p-4 justify-content-between mb-5"
        style={{ backgroundColor: "#c5e3fa", margin: "auto" }}
      >
        <div
          style={{ backgroundColor: "#8baec7" }}
          className="p-3 rounded mt-4"
        >
          <h3 className="text-light text-center">Email</h3>
          <div className="d-flex flex-column flex-xl-row justify-content-between my-4">
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded text-break">
              guidetosuccessgts@gmail.com
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded text-break">
              saddamsirgts@gmail.com
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded text-break">
              educatingruralindiagts@gmail.com
            </div>
          </div>
        </div>

        <div
          style={{ backgroundColor: "#8baec7" }}
          className="p-3 rounded mt-5 mb-4"
        >
          <h3 className="text-light text-center">Mobile</h3>
          <div className="d-flex flex-column flex-xl-row justify-content-center my-4">
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded text-break">
              +91-8877860095
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
