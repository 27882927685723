import React from "react";
import assests from "../assets/assets";

const Secondary = () => {
  return (
    <div className="container fluid">
      <div className="d-flex align-items-center justify-content-center py-lg-5 py-4">
        <img
          src={assests.images.franchise}
          className="img-fluid"
          alt="homeImage"
          style={{ width: "80px", height: "80px" }}
        />
        <div>
          <h1
            className="mb-0 ms-2 text-decoration-underline"
            style={{ color: "#178FEB" }}
          >
            Secondary
          </h1>
          <h5 className="ms-2" style={{ color: "#178FEB" }}>
            Class 9 and 10
          </h5>
        </div>
      </div>
      <div
        className="rounded p-4 justify-content-between mb-5"
        style={{ backgroundColor: "#c5e3fa", margin: "auto" }}
      >
        <h3 className="fw-bold text-secondary text-center">
          Class 9 - Subjects
        </h3>
        <div
          className="d-flex flex-column flex-xl-row justify-content-between my-4 p-3 rounded mt-4"
          style={{ backgroundColor: "#8baec7" }}
        >
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            Maths
          </div>
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            Science
          </div>
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            SST
          </div>
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            English
          </div>
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            Hindi
          </div>
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            Urdu
          </div>
        </div>
        {/*<h5 className="text-secondary py-2">Add your description.</h5>*/}
      </div>
      <div
        className="rounded p-4 justify-content-between mb-5"
        style={{ backgroundColor: "#c5e3fa", margin: "auto" }}
      >
        <h3 className="fw-bold text-secondary text-center">
          Class 10 - Subjects
        </h3>
        <div
          className="d-flex flex-column flex-xl-row justify-content-between my-4 p-3 rounded mt-4"
          style={{ backgroundColor: "#8baec7" }}
        >
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            Maths
          </div>
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            Science
          </div>
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            SST
          </div>
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            English
          </div>
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            Hindi
          </div>
          <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
            Urdu
          </div>
        </div>
        {/*<h5 className="text-secondary py-2">Add your description.</h5>*/}
      </div>
    </div>
  );
};

export default Secondary;
