import React from "react";
import assests from "../assets/assets";

const HigherSecondary = () => {
  return (
    <div className="container fluid">
      <div className="d-flex align-items-center justify-content-center py-lg-5 py-4">
        <img
          src={assests.images.franchise}
          className="img-fluid"
          alt="homeImage"
          style={{ width: "80px", height: "80px" }}
        />
        <div>
          <h1
            className="mb-0 ms-2 text-decoration-underline"
            style={{ color: "#178FEB" }}
          >
            Higher Secondary
          </h1>
          <h5 className="ms-2" style={{ color: "#178FEB" }}>
            Class 11 and 12
          </h5>
        </div>
      </div>
      <div
        className="rounded p-4 justify-content-between mb-5"
        style={{ backgroundColor: "#c5e3fa", margin: "auto" }}
      >
        <h3 className="fw-bold text-secondary text-center">
          Class 11 - Subjects
        </h3>
        <div
          style={{ backgroundColor: "#8baec7" }}
          className="p-3 rounded mt-4"
        >
          <h3 className="text-light text-center">Science</h3>
          <div className="d-flex flex-column flex-xl-row justify-content-between my-4">
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Maths
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Physics
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Chemistry
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Biology
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Computer Science
            </div>
          </div>
        </div>

        <div
          style={{ backgroundColor: "#8baec7" }}
          className="p-3 rounded mt-5"
        >
          <h3 className="text-light text-center">Commerce</h3>
          <div className="d-flex flex-column flex-lg-row justify-content-between my-4">
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Accountancy
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Business Studies
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Economics
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Others
            </div>
          </div>
        </div>

        <div
          style={{ backgroundColor: "#8baec7" }}
          className="p-3 rounded mt-5"
        >
          <h3 className="text-light text-center">Arts</h3>
          <div className="d-flex flex-column flex-xl-row justify-content-between my-4">
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              History
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Geography
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Political Science
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Sociology
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              others
            </div>
          </div>
        </div>

        {/*<h5 className="text-secondary py-2">Add your description.</h5>*/}
      </div>
      <div
        className="rounded p-4 justify-content-between mb-5"
        style={{ backgroundColor: "#c5e3fa", margin: "auto" }}
      >
        <h3 className="fw-bold text-secondary text-center">
          Class 12 - Subjects
        </h3>
        <div
          style={{ backgroundColor: "#8baec7" }}
          className="p-3 rounded mt-4"
        >
          <h3 className="text-light text-center">Science</h3>
          <div className="d-flex flex-column flex-xl-row justify-content-between my-4">
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Maths
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Physics
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Chemistry
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Biology
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Computer Science
            </div>
          </div>
        </div>

        <div
          style={{ backgroundColor: "#8baec7" }}
          className="p-3 rounded mt-5"
        >
          <h3 className="text-light text-center">Commerce</h3>
          <div className="d-flex flex-column flex-lg-row justify-content-between my-4">
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Accountancy
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Business Studies
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Economics
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Others
            </div>
          </div>
        </div>

        <div
          style={{ backgroundColor: "#8baec7" }}
          className="p-3 rounded mt-5"
        >
          <h3 className="text-light text-center">Arts</h3>
          <div className="d-flex flex-column flex-xl-row justify-content-between my-4">
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              History
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Geography
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Political Science
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              Sociology
            </div>
            <div className="bg-light px-5 py-1 text-center my-2 fw-bold rounded">
              others
            </div>
          </div>
        </div>
        {/*<h5 className="text-secondary py-2">Add your description.</h5>*/}
      </div>
    </div>
  );
};

export default HigherSecondary;
