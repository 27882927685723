import React from "react";
import assests from "../assets/assets";

const Home = () => {
  return (
    <div className="bg-light">
      <div>
        <img
          src={assests.images.cover}
          className="w-100 img-fluid"
          alt="CoverImage"
        />
      </div>
      <div>
        <div className="container fluid">
          <div className="d-flex align-items-center justify-content-center py-lg-5 py-5 ">
            <img
              src={assests.images.home}
              className="img-fluid"
              alt="homeImage"
              style={{ width: "80px", height: "80px" }}
            />
            <h1
              className="mb-0 ms-2 text-decoration-underline"
              style={{ color: "#178FEB" }}
            >
              Our Goal
            </h1>
          </div>
          <div
            className="row justify-content-lg-evenly"
            style={{ margin: "auto" }}
          >
            <div className="col-lg-4 border p-3 bg-white rounded">
              <div className="d-flex justify-content-center align-items-center pb-4">
                <h5 className="fw-bold ps-2 mb-0" style={{ color: "#178FEB" }}>
                  Guide to Success
                </h5>
              </div>

              <span className="fw-bold">GTS: </span>
              <span className="text-secondary">
                Guide to Success is an online platform designed to support rural
                children, especially girls, preparing for secondary and higher
                secondary exams. Our unique focus on concept-based learning for
                all subjects empowers students to achieve academic success
                through their own efforts.
              </span>
            </div>
            <div className="col-lg-4 border p-3 bg-white mt-lg-0 mt-5 rounded">
              <div className="d-flex justify-content-center align-items-center pb-4">
                <h5 className="fw-bold ps-2 mb-0" style={{ color: "#178FEB" }}>
                  High Quality Education
                </h5>
              </div>
              <span className="fw-bold">Quality Matters: </span>
              <span className="text-secondary">
                In rural areas, many students face challenges accessing tuitions
                due to financial hardships and distance, causing them to
                struggle in exams or drop out. Guide to Success solves this by
                providing well-qualified teachers, helping students achieve
                their dreams and progress toward success.
              </span>
            </div>
          </div>
          <div
            className="row justify-content-lg-evenly pt-lg-5 pt-5"
            style={{ margin: "auto" }}
          >
            <div className="col-lg-4 border p-3 bg-white rounded">
              <div className="d-flex justify-content-center align-items-center pb-4">
                <h5 className="fw-bold ps-2 mb-0" style={{ color: "#178FEB" }}>
                  Crash Course
                </h5>
              </div>
              <span className="fw-bold">Time is precious: </span>
              <span className="text-secondary">
                "Guide to Success helps students effectively revise and complete
                their syllabus with 45-day revision courses, including:
                <ul>
                  <li>Full syllabus coverage</li>
                  <li>Weekly tests (at least 4)</li>
                  <li>
                    Live mentorship for doubt clearing and board exam answer
                    writing.
                  </li>
                </ul>
              </span>
            </div>
            <div className="col-lg-4 border p-3 bg-white mt-lg-0 mt-5 rounded">
              <div className="d-flex justify-content-center align-items-center pb-4">
                <h5 className="fw-bold ps-2 mb-0" style={{ color: "#178FEB" }}>
                  Doubt Sessions
                </h5>
              </div>
              <span className="fw-bold">Doubts? Don't worry: </span>
              <span className="text-secondary">
                We provide weekly mentorship programs with toppers' answer sheet
                evaluations to help students master board exam answer writing.
                Additionally, one-on-one doubt-solving sessions via WhatsApp
                ensure quick resolution of any questions.
              </span>
            </div>
          </div>
          <div
            className="row justify-content-lg-evenly py-lg-5 py-5"
            style={{ margin: "auto" }}
          >
            <div className="col-lg-4 border p-3 bg-white rounded">
              <div className="d-flex justify-content-center align-items-center pb-4">
                <h5 className="fw-bold ps-2 mb-0" style={{ color: "#178FEB" }}>
                  Test Series
                </h5>
              </div>
              <span className="fw-bold">Test Your Hard Work: </span>
              <span className="text-secondary">
                "We will conduct chapter-wise tests and MCQ practice based on
                previous year question (PYQ) models to help students grasp
                topics and exam patterns. The first five submitted answer sheets
                will receive detailed corrections and personalized feedback."
              </span>
            </div>
            <div className="col-lg-4 border p-3 bg-white rounded mt-lg-0 mt-5">
              <div className="d-flex justify-content-center align-items-center pb-4">
                <h5 className="fw-bold ps-2 mb-0" style={{ color: "#178FEB" }}>
                  Experienced Teachers
                </h5>
              </div>
              <span className="fw-bold">The Guide:</span>
              <span className="text-secondary">
                Our experienced teachers focus on concept-based learning and
                provide personalized guidance tailored to each student's needs.
                This empowers students to master key concepts and achieve
                academic excellence based on their abilities, while addressing
                challenges like limited access to quality education to prepare
                them for exams and future success.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#c5e3fa", margin: "auto" }}>
        <div className="container fluid">
          <h3 className="fw-bold text-secondary py-3 text-center">Our Stars</h3>
          <div className="d-flex flex-wrap justify-content-around align-items-center py-4">
            <div className="m-3">
              <div
                className="ratio ratio-1x1 rounded-circle overflow-hidden"
                style={{ height: "150px", width: "150px" }}
              >
                <img
                  src={assests.images.student1}
                  className="img-fluid"
                  alt="Student"
                />
              </div>
              <div className="p-2">
                <h6 className="text-secondary text-center">Annu (12th)</h6>
                <h6 className="text-secondary text-center">BSEB (2023)</h6>
                <h6 className="text-secondary text-center">Marks: 392</h6>
              </div>
            </div>
            <div className="m-3">
              <div
                className="ratio ratio-1x1 rounded-circle overflow-hidden"
                style={{ height: "150px", width: "150px" }}
              >
                <img
                  src={assests.images.student2}
                  className="img-fluid"
                  alt="Student"
                />
              </div>
              <div className="p-2">
                <h6 className="text-secondary text-center">Raushani (12th)</h6>
                <h6 className="text-secondary text-center">BSEB (2023)</h6>
                <h6 className="text-secondary text-center">Marks: 398</h6>
              </div>
            </div>
            <div className="m-3">
              <div
                className="ratio ratio-1x1 rounded-circle overflow-hidden"
                style={{ height: "150px", width: "150px" }}
              >
                <img
                  src={assests.images.student3}
                  className="img-fluid"
                  alt="Student"
                />
              </div>
              <div className="p-2">
                <h6 className="text-secondary text-center">Karina (12th)</h6>
                <h6 className="text-secondary text-center">BSEB (2023)</h6>
                <h6 className="text-secondary text-center">Marks: 403</h6>
              </div>
            </div>
            <div className="m-3">
              <div
                className="ratio ratio-1x1 rounded-circle overflow-hidden"
                style={{ height: "150px", width: "150px" }}
              >
                <img
                  src={assests.images.student4}
                  className="img-fluid"
                  alt="Student"
                />
              </div>
              <div className="p-2">
                <h6 className="text-secondary text-center">Ankita (10th)</h6>
                <h6 className="text-secondary text-center">BSEB (2023)</h6>
                <h6 className="text-secondary text-center">Marks: 371</h6>
              </div>
            </div>
            <div className="m-3">
              <div
                className="ratio ratio-1x1 rounded-circle overflow-hidden"
                style={{ height: "150px", width: "150px" }}
              >
                <img
                  src={assests.images.student5}
                  className="img-fluid"
                  alt="Student"
                />
              </div>
              <div className="p-2">
                <h6 className="text-secondary text-center">Hansraj (10th)</h6>
                <h6 className="text-secondary text-center">BSEB (2024)</h6>
                <h6 className="text-secondary text-center">Marks: 417</h6>
              </div>
            </div>
            <div className="m-3">
              <div
                className="ratio ratio-1x1 rounded-circle overflow-hidden"
                style={{ height: "150px", width: "150px" }}
              >
                <img
                  src={assests.images.unknownstudent}
                  className="img-fluid bg-white p-3"
                  alt="Student"
                />
              </div>
              <div className="p-2" style={{ marginBottom: "60px" }}>
                <h6 className="text-secondary text-center">Next You?</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<div style={{ backgroundColor: "#c5e3fa" }}>
        <div className="container fluid">
          <div className="d-flex justify-content-center align-items-center py-5">
            <h1 className="fw-bold text-center text-secondary mb-0">
              Quicks - Associated Business Partner
            </h1>
          </div>
          <div className="d-flex justify-content-center align-items-center pb-5 overflow-scroll text-secondary">
            <h4 className="pe-4 ">Samsung</h4>
            <h4 className="pe-4">LG</h4>
            <h4 className="pe-4">Loyds</h4>
            <h4>Havells</h4>
          </div>
        </div>
      </div>*/}
    </div>
  );
};

export default Home;
