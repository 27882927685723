import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  useEffect(() => {
    // Extract the current route from the pathname
    const currentRoute = location.pathname;

    // Initialize activeLink based on the current route
    if (currentRoute === "/") {
      setActiveLink("Home");
    } else if (currentRoute === "/about") {
      setActiveLink("About");
    } else if (currentRoute === "/services") {
      setActiveLink("Services");
    } else if (currentRoute === "/franchise") {
      setActiveLink("Franchise");
    } else if (currentRoute === "/provider") {
      setActiveLink("Provider");
    } else if (currentRoute === "/franchise/login") {
      setActiveLink("FranchiseLogin");
    } else if (currentRoute === "/provider/login") {
      setActiveLink("ProviderLogin");
    } else if (currentRoute === "/register/firm/company") {
      setActiveLink("Register");
    } else if (currentRoute === "/contact") {
      setActiveLink("Contact");
    } else {
      setActiveLink(""); // Set a default value if needed
    }
  }, [location.pathname]); // Listen for changes in pathname

  return (
    <nav
      className="navbar navbar-expand-lg sticky-top enable-shadows box-shadow"
      style={{ backgroundColor: "#172c3b" }}
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          <svg
            width="50"
            height="50"
            viewBox="0 0 510 367"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_277_91)">
              <path
                d="M414.95 198.955L509.387 299.959C509.387 299.959 382.702 278.05 256.018 366.832C274.907 347.692 336.807 287.618 457.904 276.899C438.729 241.963 414.945 198.955 414.945 198.955H414.95Z"
                fill="#F4CE14"
              />
              <path
                d="M304.542 90.2854C304.542 90.2854 307.443 114.268 281.346 180.68C277.822 188.983 261.241 229.107 262.62 266.926C263.081 272.921 261.698 297.827 273.216 332.881C270.913 315.815 271.835 274.766 288.765 247.786C290.495 244.903 332.527 163.791 419.594 120.549C410.612 119.165 393.567 119.279 346.346 147.183C343.813 148.334 318.704 160.641 311.334 131.501C310.183 127.119 307.879 117.895 304.538 90.2812L304.542 90.2854Z"
                fill="#F4CE14"
              />
              <path
                d="M91.5957 107.579C91.5957 107.579 118.545 96.1649 175.897 111.037C184.189 113.459 231.178 135.596 250.523 90.9746C252.595 86.1315 267.797 47.7374 270.91 0C274.365 8.30197 297.859 49.3505 273.904 127.755C271.599 133.75 240.783 204.776 239.837 233.37C239.351 239.365 236.932 256.544 244.883 306.357C237.626 283.182 218.395 219.649 195.361 187.825C172.328 156.002 150.907 119.568 91.5957 107.574V107.579Z"
                fill="#379777"
              />
              <path
                d="M95.0507 198.955L0.613281 299.959C0.613281 299.959 127.298 278.05 253.983 366.832C235.093 347.692 173.193 287.618 52.0964 276.899C71.271 241.963 95.0547 198.955 95.0547 198.955H95.0507Z"
                fill="#379777"
              />
              <path
                d="M240.687 77.4951C246.378 58.0345 239.236 38.8124 224.734 34.5612C210.232 30.31 193.862 42.6396 188.171 62.1002C182.478 81.5607 189.621 100.783 204.124 105.034C218.626 109.285 234.996 96.9555 240.687 77.4951Z"
                fill="#379777"
              />
              <path
                d="M361.37 117.682C367.84 103.113 364.034 87.2725 352.869 82.3028C341.704 77.333 327.408 85.1156 320.939 99.6855C314.468 114.256 318.273 130.095 329.438 135.065C340.603 140.035 354.899 132.252 361.37 117.682Z"
                fill="#F4CE14"
              />
            </g>
            <defs>
              <clipPath id="clip0_277_91">
                <rect width="510" height="367" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </a>
        <button
          className="navbar-toggler navbar-dark"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav nav-underline mb-2 mb-lg-0 ">
            <li className="nav-item">
              <a
                className={`nav-link ${activeLink === "Home" && "active"}`}
                href="/"
                style={{ color: "#d5dde3" }}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeLink === "About" && "active"}`}
                href="/about"
                style={{ color: "#d5dde3" }}
              >
                About Us
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className={`nav-link dropdown-toggle ${
                  (activeLink === "Franchise" || activeLink === "Provider") &&
                  "active"
                }`}
                href="/"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ color: "#d5dde3" }}
              >
                Courses
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/secondary">
                    class 9 & 10
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/highersecondary">
                    class 11 & 12
                  </a>
                </li>
              </ul>
            </li>
            {/*           <li className="nav-item">
              <a
                className={`nav-link ${activeLink === "Services" && "active"}`}
                href="/services"
                style={{ color: "#d5dde3" }}
              >
                Our Services
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className={`nav-link dropdown-toggle ${
                  (activeLink === "Franchise" || activeLink === "Provider") &&
                  "active"
                }`}
                href="/"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ color: "#d5dde3" }}
              >
                Business Offers
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/franchise">
                    Franchise
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/provider">
                    Corporate Service Provider
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className={`nav-link dropdown-toggle ${
                  (activeLink === "FranchiseLogin" ||
                    activeLink === "ProviderLogin") &&
                  "active"
                }`}
                href="/"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ color: "#d5dde3" }}
              >
                Login
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/franchise/login">
                    Franchise
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/provider/login">
                    Service Provider
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className={`nav-link dropdown-toggle ${
                  activeLink === "Register" && "active"
                }`}
                href="/"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ color: "#d5dde3" }}
              >
                Register
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/register/firm/company">
                    Company/Firms
                  </a>
                </li>
              </ul>
            </li>*/}
            <li className="nav-item">
              <a
                className={`nav-link ${activeLink === "Contact" && "active"}`}
                aria-current="page"
                href="/contact"
                style={{ color: "#d5dde3" }}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
