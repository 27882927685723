import React from "react";
import assests from "../assets/assets";

const About = () => {
  return (
    <div className="">
      <div className="container fluid mb-5">
        <div className="d-flex align-items-center justify-content-center py-lg-5 py-4">
          <img
            src={assests.images.about}
            className="img-fluid"
            alt="homeImage"
            style={{ width: "80px", height: "80px" }}
          />
          <h1
            className="mb-0 ms-2 text-decoration-underline"
            style={{ color: "#178FEB" }}
          >
            About Us
          </h1>
        </div>
        <div
          className="row rounded p-4 justify-content-between"
          style={{ backgroundColor: "#c5e3fa", margin: "auto" }}
        >
          <div className="col-lg-8 ">
            <h3 className="fw-bold text-secondary">About</h3>
            <h5 className="text-secondary">
              Aiming for the removal of social pathologies from society. The
              founder of Guide to Success moved to Mukherjee Nagar, Delhi, to
              become civil servant, but the COVID pandemic brought him to his
              home village, and conditions for rural education provoked him to
              work on educating rural India under the umbrella of Guide to
              Success.
              <br />
              <br />
              Guide to Success was founded by Md Saddam with the help of a
              dedicated team consisting of experienced tutors. We provide all
              subject coaching for CBSE and all India state boards from class
              9th to 12th with organised and structured syllabus, crash course,
              test series, doubt sessions and many more. We help students from
              all over India, especially students who belong to rural India, so
              they can secure good marks from their own understanding and hard
              work
            </h5>
          </div>
          <div className="col-lg-3 d-flex justify-content-center pt-lg-0 pt-4">
            <img
              src={assests.images.profile}
              className="rounded img-fluid"
              alt="founder"
              // style={{ width: "55%", height: "100%" }}
            />
          </div>
        </div>
        {/*     <div
          className="rounded p-4 my-5"
          style={{ backgroundColor: "#c5e3fa", margin: "auto" }}
        >
          <h3 className="fw-bold text-secondary py-3">Our Team</h3>
          <div className="row justify-content-evenly">
            <div
              className="col-lg-3 text-center"
              // style={{ width: "55%", height: "100%" }}
            >
              <img
                src={assests.images.profile}
                className="rounded img-fluid"
                alt="founder"
                // style={{ width: "55%", height: "100%" }}
              />
              <h4 className="p-3 text-secondary">CEO</h4>
            </div>
            <div
              className="col-lg-3 text-center"
              // style={{ width: "55%", height: "100%" }}
            >
              <img
                src={assests.images.profile}
                className="rounded img-fluid"
                alt="founder"
                // style={{ width: "55%", height: "100%" }}
              />
              <h4 className="p-3 text-secondary">CTO</h4>
            </div>
            <div
              className="col-lg-3 text-center"
              // style={{ width: "55%", height: "100%" }}
            >
              <img
                src={assests.images.profile}
                className="rounded img-fluid"
                alt="founder"
                // style={{ width: "55%", height: "100%" }}
              />
              <h4 className="p-3 text-secondary">CFO</h4>
            </div>
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default About;
