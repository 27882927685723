const assests = {
  images: {
    cover: require("./images/cover.jpg"),
    profile: require("./images/about.jpg"),
    logo: require("./images/Fevicaon.svg"),
    unknownstudent: require("./images/person-295.png"),
    student1: require("./images/IMG-20240930-WA0002 (1).jpg"),
    student2: require("./images/IMG-20240930-WA0003 (1).jpg"),
    student3: require("./images/IMG-20240930-WA0004 (1).jpg"),
    student4: require("./images/IMG-20241001-WA0001 (1).jpg"),
    student5: require("./images/IMG-20241002-WA0000 (1).jpg"),
    franchise: require("./images/franchise.png"),
    contact: require("./images/contact-us.png"),
    frontImage: require("./images/front-image.png"),
    home: require("./images/home.png"),
    why: require("./images/why.png"),
    about: require("./images/about.png"),
    person: require("./images/person1.webp"),
    service: require("./images/services.png"),
    appliance: require("./images/appliance.png"),
    homeCare: require("./images/home-care.png"),
    construction: require("./images/construction.png"),
    corporate: require("./images/corporate.png"),
    personalCare: require("./images/personal-care.png"),
    medical: require("./images/medical.png"),
    quickMove: require("./images/quick-move.png"),
    delivery: require("./images/delivery.png"),
    outStay: require("./images/out-stay.png"),
    monthly: require("./images/monthly.png"),
    academy: require("./images/academy.png"),
    marriage: require("./images/marriage.png"),
    android: require("./images/google-play-.png"),
    apple: require("./images/Apple.png"),
    download: require("./images/Download.png"),
    competitiveRate: require("./images/competative-rate.png"),
    quickService: require("./images/quick-service.png"),
    safeAndSecure: require("./images/safe-and-secure.png"),
    cashLess: require("./images/cash-less.png"),
    serviceAtLocation: require("./images/service-at-location.png"),
    oneAppAllService: require("./images/single-app.png"),
  },
};
module.exports = assests;
